<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-card class="px-2 mx-2" elevation="0">
      <v-data-table
        :headers="headers"
        :items="dataTable"
        :items-per-page="defaultPerPage"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row class="pt-4 px-3">
            <v-col cols="12" sm="5" class="">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="#36AC87"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>

            <v-col cols="12" sm="5" class="">
              <v-combobox
                v-model="filterSectorSelected"
                :items="filterSectorOpt"
                color="#36AC87"
                item-text="sector_name"
                item-value="sector_hash"
                placeholder="Pilih Bidang"
                outlined
                dense
                @change="initialize"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.city`]="{ item }">
          {{ item.city || "-" }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div
            class="action-container"
            style="
              width: 36px;
              height: 29px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #f2f2f2;
              box-sizing: border-box;
              border-radius: 4px;
              cursor: pointer;
            "
            @click="toDetail(item)"
          >
            <v-icon class="action-icon"> mdi-eye </v-icon>
          </div>
        </template>

        <template v-slot:[`item.desc`]="{ item }">
          <div style="max-width: 200px; white-space: break-spaces">
            {{ item.desc }}
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#2E976C"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic">
              {{ itemCount + " dari " + itemTotal + " ditampilkan" }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <modal-detail
      :show="dialog"
      :data-modal="dataDetail"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import ModalDetail from "../keanggotaan/ModalDetail.vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {
    ModalDetail,
  },

  data: () => ({
    dialog: false,
    dataDetail: null,
    defaultPerPage: 5,

    search: "",
    filterSectorSelected: {
      sector_name: "Semua Sektor",
      sector_hash: null,
    },
    filterSectorOpt: [],
    headers: [
      { text: "Perusahaan", align: "start", value: "name", sortable: false },
      { text: "Nama Pemilik", value: "member_name", sortable: false },
      { text: "Bidang", value: "sector", sortable: false },
      { text: "Deskripsi", value: "desc", sortable: false },
      { text: "Domisili", value: "city", sortable: false },
      { text: "Telepon", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Website", value: "website", sortable: false },
      { text: "", value: "actions" },
    ],
    dataTable: [],
    page: 1,
    pageCount: 0,
    itemCount: 0,
    itemTotal: 0,
  }),

  computed: {},

  watch: {},

  created() {
    this.initialize();
    this.getCompanySector();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    toDetail(p) {
      // console.log(p)
      this.getDetail(p.mc_hash);
      this.dialog = true;
    },

    onChangePage(e) {
      this.page = e;
      this.initialize(e);
    },

    filterData() {
      this.initialize();
    },

    filterClear() {
      this.initialize();
    },

    initialize(page = 1) {
      const requestBody = {
        page: page,
        perpage: this.defaultPerPage,
        search: this.search,
        sector_hash: this.filterSectorSelected.sector_hash || null,
      };

      axios
        .post("/v1/admin/company", requestBody)
        .then((res) => {
          this.dataTable = null;
          if (res.data.status === 200) {
            this.dataTable = res.data.data.company.list;
            this.pageCount = res.data.data.company.pagination.last_page;
            this.itemCount = res.data.data.company.pagination.count;
            this.itemTotal = res.data.data.company.pagination.total;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getDetail(p) {
      const requestBody = {
        mc_hash: p,
      };

      axios
        .post("/v1/admin/company/detail", requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data)
            this.dataDetail = res.data.data;
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getCompanySector() {
      axios.post("/v1/general/get-company-sector").then((res) => {
        if (res.data.status === 200) {
          // console.log(res.data.data);
          this.filterSectorOpt = res.data.data.sector.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2b7dec;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2b7dec;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
